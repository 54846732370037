<template>
  <div :class="`${prefixCls}-safe`" id="aochenSafe">
    <Separate title="安全管理" desc="SECURITY MANAGEMENT" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div :class="`${prefixCls}-safe-content`">
      <custom-card class="card" v-for="item in safeList" :key="item.id" :value="item" @click="handleClick" />
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import CustomCard from '@/components/CustomCard/index.vue'
import { siteNewsPage } from '@/api/index'
export default {
  name: 'QualificationsHonors',
  data () {
    return {
      prefixCls: this.$prefix + '-company',
      safeList: []
    }
  },
  mixins: [WowMixin],
  components: {
    CustomCard,
    Separate
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    handleClick (data) {
      this.$router.push({ path: '/detail', query: { id: data.id } })
    },
    siteNewsPage () {
      siteNewsPage(
        {
          channelId: '30'
        },
        {
          channelId: 0
        }
      ).then(({ data }) => {
        this.safeList = data.data.rows
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -company-safe;
.#{$prefixCls}{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: $content-width;
  padding-bottom: 50px;
  &-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .card {
      width: 380px;
      margin: 0 25px 25px 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>
